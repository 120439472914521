@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use '../../../style/_styles' as *


div.cp_component_navigation-bar-lists-container
    flex-grow: 1
    overflow-y: hidden
    padding: 0
    

div.cp_component_navigation-bar-list-container
    display: flex
    box-sizing: border-box
    height: px-to-rem(48px)
    padding: 0

    > button
        background: inherit
        border: none
        cursor: pointer
        display: flex
        padding: 0.75rem $space_3x 0.75rem $space_2x
        width: 100%
    
    .cp_component_navigation-bar-list-icon
        height: px-to-rem(24px)
        width: px-to-rem(24px)

    .cp_component_navigation-bar-list-icon
        fill: $color_icon-pill

    .cp_component_navigation-bar-list-name
        color: $color_text-primary
        font-family: $font-family-primary
        font-size: $font-size-normal
        font-weight: $font-weight-normal
        margin-left: $space_1x
    
    .cp_component_navigation-bar-list-flag-icon
        margin-left: $space_1x

    &:hover
        background-color: $color_fill-list-hovered

.dark
    div.cp_component_navigation-bar-list-container
        .cp_component_navigation-bar-list-name
            color: dark.$color_text-primary

        .cp_component_navigation-bar-list-icon
            fill: dark.$color_icon-pill

        &:hover
            background-color: dark.$color_fill-list-hovered
