@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *
@use "../../style/primitives" as *

// Light Theme Styles
.cp_component_search-ai-results
  height: 100%

  &-totals
    color: $color_text-inactive

  > aside
    align-items: flex-start
    display: flex
    height: 1.9rem
    justify-content: space-between
    margin-bottom: 1rem

    > div
      align-items: center
      display: flex
      gap: $space_2x

      .MuiTypography-root
        color: $color_text-inlinebutton
        font-size: px-to-rem(10px)
        text-decoration: underline
        &:hover
          cursor: pointer

  .cp_component_search-results-csv-download-button

    &.enabled-csv-icon
      background-color: none !important
      border-color: none !important
      color: $color_icon-pill
      &.MuiIconButton-root
        &.outlined
          background-color: $color_fill-card-panel
          border: none

    &.disabled-csv-icon
      background-color: none !important
      border-color: none !important
      color: $color_gray-400
      &.MuiIconButton-root
        &.outlined
          background-color: $color_fill-card-panel
          border: none

  // Result Type Dropdown
  div.cp_component_search-ai-results-type-dropdown
    align-items: center
    display: flex
    height: 100%
    justify-content: space-between

    svg
      color: $color_text-secondary

  // Search Input
  // TODO: If these "thinner" MUI inputs will be a trend, then move these
  // styles into the base component and make them reusable
  div.cp_component_input.cp_component_search-ai-results-search-input
    background-color: $color_fill-card

    .MuiInputBase-root
      border-radius: 5px
      fieldset
        border-color: $color_border-section

    .MuiInputBase-input
      padding: 0.3rem 0.6rem

    .MuiFormLabel-root
      transform: translate(14px, 5px) scale(1)

    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-root.MuiFormLabel-filled
      font-size: 0.95rem
      transform: translate(14px, -9px) scale(0.75)

  section.cp_component_search-ai-results-table-container
    display: flex
    height: calc(100% - 2.9rem)

  svg.cp_component_search-ai-results-search-icon
    color: $color_text-primary
    height: 1.25rem

  // Check marks within Sort By menu
  svg.cp_component_search-ai-results_disabled
    opacity: 0

// Dark Theme Styles
.dark .cp_component_search-ai-results
  &-totals
    color: dark.$color_text-inactive
  > aside > div
    .MuiTypography-root
      color: dark.$color_text-inlinebutton

  .cp_component_search-results-csv-download-button

    &.enabled-csv-icon
      color: dark.$color_icon-white
      &.MuiIconButton-root
        &.outlined
          background-color: $color_gray-dark-900

    &.disabled-csv-icon
      color: $color_gray-400
      &.MuiIconButton-root
        &.outlined
          background-color: $color_gray-dark-900

  // Result Type Dropdown
  div.cp_component_search-ai-results-type-dropdown svg
    color: dark.$color_text-secondary

  // Search Input
  div.cp_component_input.cp_component_search-ai-results-search-input
    background-color: dark.$color_fill-card

    .MuiInputBase-root
      fieldset
        border-color: dark.$color_border-section

  svg.cp_component_search-ai-results-search-icon
    color: dark.$color_text-primary
