@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.reviewer-groups-list
  padding: $space_3x
  .groups-grid
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
    gap: $space_2x

  .user-actions
    color: $color_text-primary
  .group-container
    border-radius: $content-border-radius
    background-color: $color_fill-background

    .group-header
      align-items: center
      display: flex
      justify-content: space-between
      padding: $space_2x

      .group-name
        color: $color_text-primary
        font-size: 1rem
        font-weight: $font-weight-bold

      .MuiIconButton-root
        // color: $color_icon-white

    .users-list
      max-height: px-to-rem(calc(4 * 64px))
      overflow-y: auto
      padding: $space_1x

      .user-item
        align-items: center
        border-radius: $content-border-radius
        display: flex
        height: px-to-rem(60px)
        justify-content: space-between
        padding: $space_1x

        &:hover
          background-color: $color_fill-list-hovered

        .user-info
          align-items: center
          display: flex
          gap: $space_1x

          .user-avatar
            border-radius: 50%
            height: px-to-rem(32px)
            width: px-to-rem(32px)
            // background-color: $color_fill-avatar

          .user-details
            .MuiTypography-subtitle1
              color: $color_text-primary
              font-size: $font-size-sm
              // font-weight: $font-weight-medium

            .textSeconday
              color: $color_text-secondary
              // font-size: $font-size-xs

  .group-menu
    .MuiPaper-root
      background-color: $color_fill-card

    .MuiMenuItem-root
      color: $color_text-primary

      &:hover
        background-color: $color_fill-list-hovered

.dark
  .MuiPaper-root
    background-color: dark.$color_fill-dropdown-select-secondary
    color: dark.$color_text-primary

  .reviewer-groups-list
    margin-bottom: $space_3x

    .user-actions
      color: dark.$color_text-primary
      .MuiIconButton-root
        // color: dark.$color_icon-white
    .group-container
      background-color: primitives.$color_gray-dark-900-a25

      .group-header

        .group-name
          color: dark.$color_text-primary

        .MuiIconButton-root
          // color: dark.$color_icon-white

      .users-list
        .user-item
          &:hover
            background-color: dark.$color_fill-list-hovered

          .user-info
            .user-avatar
              background-color: dark.$color_fill-avatar

            .user-details
              .MuiTypography-subtitle1
                color: dark.$color_text-primary

              .textSeconday
                color: dark.$color_text-secondary

    .group-menu
      background-color: dark.$color_fill-dropdown-select-secondary

      .MuiMenuItem-root
        color: dark.$color_fill-dropdown-select-secondary

        &:hover
          background-color: dark.$color_fill-list-hovered
