@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_deliverable-v2-content-overview_component
    align-items: center
    background-color: $color_surface-secondary
    border-bottom: 1px solid $color_border-section
    display: flex
    justify-content: space-between
    padding: $space_2x
    margin-left: -$space_3x
    margin-right: -$space_3x
    &-left
        display: flex
        gap: $space_2x
        &-details
            display: flex
            flex-direction: column
            &-name
                line-height: 2.2
            &-demographics
                color: $color_text-follower
                font-size: 0.75rem
                font-weight: 700
    &-right
        align-items: center
        display: flex
        gap: $space_1x
        margin-right: 1rem
        height: 100%
        float: left
        .MuiButtonBase-root
            margin-top: 0.25rem
    &-review
        gap: $space_1x
        display: flex
        align-items: center
        height: $space_5x
        .MuiButton-root
            min-width: 10rem
            height: inherit
        .MuiFormControl-root
            border-radius: 2px
            border: 1px solid var(--border-border-secondarybutton, #191919)
            font-weight: 700
            height: inherit
            .MuiInputBase-root
                height: $space_4x
        div.deliverable-select
            .MuiInputBase-input
                font-weight: 700
                text-transform: uppercase
        .finalize-feedback-buttons
            .MuiButton-root
                height: px-to-rem(32px)
                padding: 0.75rem
            .MuiSvgIcon-root
                height: px-to-rem(16px)
                width: px-to-rem(16px)
            // .finalize-feedback-button
            //     border: none
            //     min-width: 9rem
            //     height: px-to-rem(28px)
            // .finalize-feedback-button-icon
            //     border: none
            //     border-left: 1px solid $color-border-buttongroup
            //     height: px-to-rem(28px)
            //     width: px-to-rem(26px)
        .MuiListItemButton-root
            align-items: center
            display: flex
            gap: $space_1x

    .approved-icon
        fill: $color_color-success
    .request-revision-icon
        fill: $color_color-warning
    .approve-dropdown-buttons
        .MuiButton-root
            height: px-to-rem(32px)
            padding: 0.75rem
        .MuiSvgIcon-root
            height: px-to-rem(16px)
            width: px-to-rem(16px)
        .dropdown-button-title-container
            align-items: center
            display: flex
            gap: $space_1x
            p
                font-size: px-to-rem(12px)
                font-weight: 700
                text-transform: uppercase
                color: $color_border-secondarybutton
    .dropdown-button-divider.cp_component_divider
        margin: $space_1x

.dark
    .cp_deliverable-v2-content-overview_component
        background-color: dark.$color_surface-primary
        border-bottom: 1px solid dark.$color_border-section
        &-left-details-demographics
            color: dark.$color_text-follower
        &-review
            .finalize-feedback-button-group
                border-color: dark.$color_button-primary
                .finalize-feedback-button-icon
                    border-color: dark.$color-border-buttongroup
        .approved-icon
            fill: dark.$color_color-success
        .request-revision-icon
            fill: dark.$color_color-warning
        .dropdown-buttons
            .MuiSvgIcon-root
        .dropdown-button-title-container
            p
                color: dark.$color_border-secondarybutton
