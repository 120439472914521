@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_component_list-group-context-menu-button
  
.cp_component_list-group-context-menu
  .MuiPaper-root
    background-color: $color_fill-modal
  .MuiMenuItem-root
    color: $color_text-primary
    font-size: px-to-rem(12px)

.dark
  .cp_component_list-group-context-menu-button

  .cp_component_list-group-context-menu
    .MuiPaper-root
      background-color: dark.$color_fill-modal
    .MuiMenuItem-root
      color: dark.$color_text-primary