@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

@mixin textStyles($color, $fontSize, $fontWeight) 
  color: $color
  font-family: $font-family-primary
  font-size: $fontSize
  font-style: $font-style-primary
  font-weight: $fontWeight
  line-height: normal

  
.cp_component_modal-create-list
  > div.MuiPaper-root
    min-height: 800px
    padding: $space_3x $space_4x

    h2.MuiDialogTitle-root
      @include textStyles($color_text-primary, $space-3x, 600)
      align-items: center
      display: flex
      letter-spacing: 0.36px
      padding: 0 0 px-to-rem(22px)

    div.MuiDialogContent-root
      margin-bottom: $space_3x
      overflow-y: unset
      padding: 0

    div.MuiDialogActions-root
      background: transparent
      border-top: none
      padding: 0
      
  .cp_component_modal-create-list
    &_wrapper
      padding-top: $space_2x
    &_wrapper-empty
      display: flex
      justify-content: center
      align-items: center
      min-height: 400px
    &_tabs
      box-shadow: inset 0 -2px $color_border-footer
      margin-bottom: $space_3x
      min-height: $space_2x

      button.MuiButtonBase-root
        align-items: flex-start
        min-height: $space_4x
        min-width: px-to-rem(58px) 
        padding: 0 0 0.25rem

        &:not(:first-child)
          align-items: center
          padding-left: 30px
          padding-right: 30px

    &_tab-details
      > div > div
        position: relative

        .cp_component_modal-create-list-divider
          margin-bottom: $space_3x

    &_row
      display: flex
      flex-direction: row
      gap: $space_2x
      width: 100%
      @media screen and (max-width: $lg)
       flex-wrap: wrap

    &_column:first-child, 
    &_list-settings,
    &_list-notifications
      border-radius: $content-border-radius
      border: 1px solid $color_border-section
      padding: $space_3x $space_4x

      h4.headline-small
        font-family: $font-family-primary
        font-size: px-to-rem(20px)
        font-style: $font-style-primary
        letter-spacing: 0.38px
        line-height: normal
        margin-bottom: $space_3x

.dark
  .cp_component_modal-create-list 
    .cp_component_modal-create-list_column:first-child,
    .cp_component_modal-create-list_list-settings,
    .cp_component_modal-create-list_list-notifications
      border: 1px solid dark.$color_border-section

      

        






