@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_campaign-deliverable-content-note-v2
    padding: $space_2x
    background-color: $color_fill-modal
    border-radius: $content-border-radius
    .body_large
        color: $color_text-primary
        font-size: 1rem
        font-weight: $font-weight-bold
    &-text
        color: $color_text-secondary
        font-size: px-to-rem(14px)
        font-weight: $font-weight-normal

.dark
    .cp_campaign-deliverable-content-note-v2
        background-color: dark.$color_fill-modal
        .body_large
            color: dark.$color_text-primary
        &-text
            color: dark.$color_text-secondary
