@use "./style/theme_light" as *
@use "./style/theme_dark" as dark
  
@import "./style/reset"
@import "./style/font"
@import "./style/typography"

// Global Body Styles
body
  background-color: $color_fill-background
  font-family: "Open Sans", sans-serif
  font-size: 16px
  font-weight: 400

  // Scrollbar Styles
  scrollbar-width: thin
  scrollbar-color: $color_scrollbar transparent

// Global Body Dark Mode Styles
body.dark
  background-color: dark.$color_fill-background

  // Scrollbar Styles
  scrollbar-color: dark.$color_scrollbar transparent
