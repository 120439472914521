@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-campaign

    &_vertical-description
        margin-bottom: $space_2x
        margin-top: $space_1x


    .MuiFormControlLabel-label
        font-size: $font-size-sm
        font-weight: $font-weight-normal

    &_input
        margin-bottom: $space_2x

    &-button_narrow
        max-height: $space_3x

    &-hidden
        visibility: hidden

    .MuiFormControlLabel-labelPlacementEnd
        min-width: px-to-rem(116px)
        margin-right: 0

    .cp_component_divider
        margin: px-to-rem(45px) 0 $space_3x 0

    &-tik-tok
        border: 1px solid $color_border-section
        border-radius: $content-border-radius
        padding: $space_2x
        height: fit-content
        &-email span
            font-family: $font-family-primary
            font-size: 0.75rem
        
        &-header
           align-items: center
           display: flex
           justify-content: space-between

        &-content
            display: block
            .label_large-bold
                text-transform: uppercase
            .MuiDivider-root
                margin: $space_1x 0
            .MuiFormControlLabel-labelPlacementEnd
                margin-left: $space_1x
            .cp_component_input
                margin-top: $space_2x
        p.label_small-caps-bold
            color: $color_text-primary
            margin: $space_2x 0 $space_1x 0
        .component_select
            width: 100%

    &-lists
        border: 1px solid $color_border-section
        border-radius: $content-border-radius
        height: fit-content
        overflow-y: auto
        padding: $space_2x

        p.body_small
            color: $color_text-secondary

        .headline_small
            margin-bottom: $space_2x

        &-autocomplete-container
            margin: $space_2x 0
        
        &-selected-list.MuiListItem-root:last-child
            border-bottom: 0
        &-selected-list.MuiListItem-root
            border-bottom: 1px solid $color_divider-section
            justify-content: space-between
            flex-wrap: wrap
        &-selected-list-left
            align-items: center
            display: flex
            gap: $space_2x
            flex-wrap: wrap
            &-description
                p
                    align-items: center
                    color: $color_text-dropdown
                    display: flex
                    font-size: 0.75rem
                    gap: 3px

                    .MuiSvgIcon-root
                        color: inherit
                        font-size: inherit
                strong
                    color: $color_text-follower
                    font-size: 0.75rem
                    text-transform: none
            .MuiAvatar-root.cp-avatar.cp_component_network-avatar + .MuiBadge-badge
                right: 45%
                .cp-network-icon
                    height: 1rem
                    width: 1rem
                    > i.cp-network-icon-svg
                        height: 66%
                        width: 66%

            .MuiAvatar-root
                margin-right: $space_2x
            .MuiButtonBase-root
                padding: px-to-rem(4px)

    &-details
        padding-right: $space_6x
        > .cp_component_input:not(:last-child)
            margin-bottom: $space_2x
        div.cp_component_input
            width: 100%
            &.cp_component_modal-campaign-details-input_budget
                width: 50%
                @media screen and (max-width: $md)
                    width: 100%
            &.hidden-budget-value
                display: none
            .MuiFormHelperText-root
                color: inherit
                margin-top: $space_1x
        &-label
            align-items: center
            display: flex
            justify-content: space-between
            margin-bottom: $space_1x
            p.label_small-caps-semibold
                color: $color_text-primary
            p.label_small-caps-bold
                color: $color_text-primary
                margin-bottom: 0
                &.counter
                    color: $color_text-secondary
                    font-weight: 400
        p.label_large-bold
            font-size: 0.9rem
            margin-bottom: $space_1x
        &-dates
            display: flex
            gap: $space_1x
            .MuiStack-root
                padding-top: 0
            .cp_datePicker, .MuiStack-root, .MuiInputBase-root
                height: px-to-rem(38px)
                overflow-y: hidden
                svg
                    font-size: 1.25rem
                input
                    height: px-to-rem(38px)
                    padding-bottom: 0
                    padding-top: 0

            &-container
                margin-bottom: $space_2x
            &-spacer
                min-width: $space_1x
            p.label_small-caps-semibold
                color: inherit
                padding-bottom: $space_1x
                small
                    color: inherit
                    font-size: 0.75rem
                    font-weight: $font-weight-normal
                    margin-left: $space_1x
            .MuiInputAdornment-root .MuiIconButton-root
                background-color: transparent
                color: $color_icon-search

        &-links
            margin-top: $space_2x 
            width: 80%
            &-inputs
                background-color: $color_button-exit
                display: flex
                flex-direction: column
                gap: $space_2x
                padding: $space_2x
                .MuiFormControl-root
                    background: transparent
            &-add, &-add:hover
                background-color: inherit
            &-chips
                display: flex
                flex-wrap: wrap
                gap: $space_2x
                margin-top: $space_2x
                .MuiChip-root
                    height: fit-content
                    max-width: px-to-rem(150px)
                    padding: $space_1x
                    .MuiChip-label
                        font-size: $font-size-sm
                        font-weight: $font-weight-bold
                        line-height: normal
                        text-transform: uppercase
                        small
                            color: $color_text-pill-secondary
                            display: block
                            font-size: $font-size-sm
                            font-weight: $font-weight-normal
                            overflow: inherit
                            text-overflow: inherit
                            text-transform: lowercase
                            white-space: inherit


    h4.headline_small
        font-size: $font-size-large

    .MuiDialog-container .MuiDialogTitle-root + .MuiDialogContent-root
        padding-top: 0

    &_photo
        &-badge
            margin-bottom: $space_2x
        &-uploader
            align-items: center
            display: flex
            gap: px-to-rem(20px)
            justify-items: start
            margin-bottom: $space_2x
        
        .image-upload .MuiAvatar-root
            background: $color_fill-social-background
            border: 2px solid $color_border-section
            cursor: pointer
            svg.MuiSvgIcon-root
              height: 2rem
              width: 2rem

        .cp-avatar.MuiAvatar-root
            font-size: xx-large
            height: px-to-rem(80px)
            width: px-to-rem(80px)
        .MuiBadge-badge
            right: $space_1x
            top: $space_1x
            button.cp_avatar-removal
                background-color: $color_surface-brand
                border-radius: 50%
                color: $color_surface-primary
                height: px-to-rem(22px)
                position: absolute
                top: 40px
                right: 0
                width: px-to-rem(22px)
                svg.MuiSvgIcon-root 
                    width: $space_2x
                    height: $space_2x

    &_row
        display: flex
        flex-direction: row
        gap: $space_3x
        width: 100%
        
    &_column
        display: flex
        flex: 0 0 40%
        flex-direction: column
        gap: $space_2x
        padding-right: $space_1x
        
    &_column_2
        display: flex
        flex: 0 0 60%
        flex-direction: column

    &_panels
        gap: $space_2x 
        margin-top: $space_2x
        max-height: px-to-rem(565px)

        > .cp_component_modal-campaign_column_2
            border: 1px solid $color_border-section
            border-radius: $content-border-radius
            overflow-y: auto
            padding: $space_3x $space_4x
        &-title
            font-size: $font-size-large
            font-weight: $font-weight-bold
            margin-bottom: $space_1x

.dark

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_modal-campaign_verticals-list

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select

    .cp_component_modal-campaign
        &_panels
            > .cp_component_modal-campaign_column_2
                border: 1px solid dark.$color_border-section
        &_photo .image-upload .MuiAvatar-root
            background: dark.$color_fill-social-background
            border: 2px solid dark.$color_border-section
        &_photo .MuiBadge-badge button.cp_avatar-removal
            background-color: $color_brand-blue-teal

        .MuiAutocomplete-inputRoot

            .MuiInputAdornment-root
                color: dark.$color_border-input

        &-details
            &-label
                p.label_small-caps-semibold
                    color: dark.$color_text-primary
                p.label_small-caps-bold
                    color: dark.$color_text-primary
                    &.counter
                        color: dark.$color_text-secondary

            div.cp_component_input .MuiInputAdornment-root .MuiSvgIcon-root
                color: dark.$color_icon-search

            &-dates
                .MuiInputAdornment-root .MuiIconButton-root
                    color: dark.$color_icon-search
                .cp_datePicker
                    .MuiInputBase-root
                        color: inherit
                    .MuiOutlinedInput-notchedOutline
                        border-color: inherit

            &-links
                &-inputs
                    background-color: dark.$color_button-exit
                .MuiChip-root small
                    color: dark.$color_text-pill-secondary
        &-lists
            border-color: dark.$color_border-section
            p.body_small
                color: dark.$color_text-secondary
                margin-top: $space_1x

            &-selected-list
                border-color: dark.$color_divider-section
                &-selected-list-left
                    &-description
                        p
                            color: dark.$color_text-dropdown
                        strong
                            color: dark.$color_text-follower

        &-tik-tok
            border-color: dark.$color_border-section
            p.label_small-caps-bold
                color: dark.$color_text-primary
