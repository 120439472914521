@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_media-feedback-card_component
    background-color: $color_fill-background
    border-radius: $content-border-radius
    padding: $space_2x
    &-header
        display: flex
        justify-content: space-between

        &-name-company, &-date
            color: $color_text-follower
            font-size: $font-size-sm
            font-weight: $font-weight-bolder

    &-body p
            color: $color_text-body
            font-size: $font-size-sm
            white-space: pre-line
        
    .MuiDivider-root
        margin-bottom: $space_1x
        margin-top: $space_1x

.dark
    .cp_media-feedback-card_component
        background-color: dark.$color_fill-section
        &-header
            &-name-company, &-date
                color: dark.$color_text-follower

        &-body p
                color: dark.$color_text-body
