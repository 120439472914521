@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_list-overview_component
    &-right-column
        display: flex
        flex: 4
        gap: 3rem
        justify-content: flex-start
        padding: $space_2x
        padding-left: 3.5rem
        @media screen and (max-width: $md)
            padding-left: $space_2x


    &-controls
        align-items: flex-start
        display: flex
        flex: 1
        gap: $space_1x
        justify-content: flex-end
        margin-bottom: auto
        @media screen and (max-width: $md)
            flex-wrap: wrap

        .MuiIconButton-root
            border-radius: 5px
            background-color: $color_button-exit
            color: $color_icon-exit
            height: px-to-rem(34px)
            width: px-to-rem(34px)

    &-metrics       
        align-items: center
        display: flex
        flex: 4
        gap: $space_6x
        justify-content: flex-start
    &-metrics-wrapper
        display: flex
        flex-direction: column
    
    &-metric
        align-items: center
        display: flex
        flex-direction: row
        gap: $space_1x
        height: 100%
        justify-content: center
        align-content: center

        &-value
            @include textStyles($color_text-primary, $font-size-normal, $font-weight-bolder)
            text-transform: uppercase
            .cp-network-icon
                border-radius: none
                height: $space_2x
                width: $space_2x
                .cp-network-icon-svg
                    height: $space_2x
                    width: $space_2x
                    
                &_instagram
                    background-color: inherit
                    .cp-network-icon-svg
                        background-image: url($instagram-icon-text-primary)

                &_facebook
                    background-color: inherit
                    .cp-network-icon-svg
                        background-image: url($facebook-icon-text-primary)

                &_youtube
                    background-color: inherit
                    .cp-network-icon-svg
                        background-image: url($youtube-icon-text-primary)

                &_snapchat
                    background-color: inherit
                    .cp-network-icon-svg
                        background-image: url($snapchat-icon-base)

                &_tiktok
                    background-color: inherit
                    .cp-network-icon-svg
                        background-image: url($tiktok-icon-text-primary)


.dark
    .cp_list-overview_component
        &-metric-label
            color: dark.$color_text-primary
        &-metric-value
            color: dark.$color_fill-icon-list
        &-controls .MuiIconButton-root
            background-color: dark.$color_button-exit
            color: dark.$color_icon-exit
    
        &-metric
            &-value
                @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bolder)
                .cp-network-icon
                    &_instagram
                        background-color: dark.$color_fill-card
                        .cp-network-icon-svg
                            background-image: url($instagram-icon-text-primary-dark)

                    &_facebook
                        background-color: dark.$color_fill-card
                        .cp-network-icon-svg
                            background-image: url($facebook-icon-text-primary-dark)

                    &_youtube
                        background-color: dark.$color_fill-card
                        .cp-network-icon-svg
                            background-image: url($youtube-icon-text-primary-dark)

                    &_snapchat
                        background-color: dark.$color_fill-card
                        .cp-network-icon-svg
                            background-image: url($snapchat-icon-base)

                    &_tiktok
                        background-color: dark.$color_fill-card
                        .cp-network-icon-svg
                            background-image: url($tiktok-icon-text-primary-dark)

