@use "../style/theme_dark" as dark
@use "../style/theme_light" as *
  
// NOTE: The initial value that `rem` inherits from is set in the root
// `index.sass` file at the root of the `src` directory, under `body` styles.

// NOTE: Styles are based off of Figma typography template, found here:
// https://www.figma.com/file/NOoIv8U8Ih5vPC6csmlKo0/React-Component-%5BNEW-BRANDING%5D?node-id=193%3A342&mode=dev

// LIGHT MODE TYPOGRAPHY STYLES
// DISPLAY HEADERS
// Display Large
h1
  color: $color_text-primary
  font-size: 3.5rem
  font-weight: 400
  line-height: 112%

h1.headline
  color: $color_text-primary
  font-family: "IBM Plex Sans", sans-serif
  font-size: 3rem
  font-weight: 600
  line-height: 112%

h1.headline_splash
  color: $color_text-primary
  font-family: "IBM Plex Sans", sans-serif
  font-size: 12rem
  font-weight: 600
  letter-spacing: 0.75rem
  line-height: 112%

// Display Medium
h2
  color: $color_text-primary
  font-size: 2.8rem
  font-weight: 300
  line-height: 115%

// Display Small
h3
  color: $color_text-primary
  font-size: 2.1rem
  font-weight: 700
  line-height: 130%

// HEADLINE HEADERS
// Headline Large
h4.headline_large
  color: $color_text-primary
  font-size: 2rem
  font-weight: 400
  line-height: 125%

// Headline Medium
h4, h4.headline_medium
  color: $color_text-primary
  font-size: 1.625rem
  font-weight: 400
  line-height: 128%

// Headline Small
h4.headline_small
  color: $color_text-primary
  font-size: 1.5rem
  font-weight: 600
  line-height: 133%

// TITLE HEADERS
// Title Large
h5.title_large
  color: $color_text-primary
  font-size: 1.4rem
  font-weight: 400
  line-height: 128%

// Title Medium
h5, h5.title_medium
  color: $color_text-primary
  font-size: 1rem
  font-weight: 600
  line-height: 150%

// Title Small
h5.title_small
  color: $color_text-primary
  font-size: 0.9rem
  font-weight: 400
  line-height: 143%

// PARAGRAPH/BODY TEXT
// Body Large
p.body_large
  color: $color_text-body
  font-size: 1.1rem
  font-weight: 400
  line-height: 133%

// Body Medium
p, p.body_medium
  color: $color_text-body
  font-size: 1rem
  font-weight: 400
  line-height: 142%

// Body Small
p.body_small, span.body_small
  color: $color_text-body
  font-size: 0.75rem
  font-weight: 400
  line-height: 133%

// LABELS
// Label Large
p.label_large,
span.label_large
  color: $color_text-primary
  font-size: 1rem
  font-weight: 400

// Label Medium
p.label_medium,
span.label_medium
  color: $color_text-inactive
  font-size: 0.9rem
  font-weight: 400

// Label Small
p.label_small,
span.label_small
  color: $color_text-primary
  font-size: 0.7rem
  font-weight: 700

// Label Large (bold)
p.label_large-bold,
span.label_large-bold
  color: $color_text-primary
  font-size: 1rem
  font-weight: 700

// Label Large (uppercase and bold)
p.label_large-caps-bold,
span.label_large-caps-bold
  color: $color_text-inactive
  font-size: 1rem
  font-weight: 700
  text-transform: uppercase

// Label Small (uppercase and bold)
p.label_small-caps-bold,
span.label_small-caps-bold
  color: $color_text-inactive
  font-size: 0.75rem
  font-weight: 700
  text-transform: uppercase

// Label Small (uppercase and semibold)
p.label_small-caps-semibold,
span.label_small-caps-semibold
  color: $color_text-secondary
  font-size: 0.75rem
  font-weight: 600
  text-transform: uppercase

// Label Extra Small (uppercase and semibold)
p.label_extrasmall-caps-semibold,
span.label_extrasmall-caps-semibold
  color: $color_text-inactive
  font-size: 0.6rem
  font-weight: 700
  text-transform: uppercase

p.inactive
  color: $color_text-inactive

// DARK MODE TYPOGRAPHY STYLES
.dark
  // DISPLAY HEADERS
  // Display Large
  h1
    color: dark.$color_text-primary

  h1.headline
    color: dark.$color_text-primary

  h1.headline_splash
    color: dark.$color_text-primary

  // Display Medium
  h2
    color: dark.$color_text-primary

  // Display Small
  h3
    color: dark.$color_text-primary

  // HEADLINE HEADERS
  // Headline Large
  h4.headline_large
    color: dark.$color_text-primary

  // Headline Medium
  h4, h4.headline_medium
    color: dark.$color_text-primary

  // Headline Small
  h4.headline_small
    color: dark.$color_text-primary

  // TITLE HEADERS
  // Title Large
  h5.title_large
    color: dark.$color_text-primary

  // Title Medium
  h5, h5.title_medium
    color: dark.$color_text-primary

  // Title Small
  h5.title_small
    color: dark.$color_text-primary

  // PARAGRAPH/BODY TEXT
  // Body Large
  p.body_large
    color: dark.$color_text-body

  // Body Medium
  p, p.body_medium
    color: dark.$color_text-body

  // Body Small
  p.body_small, span.body_small
    color: dark.$color_text-body

  // LABELS
  // Label Large
  p.label_large,
  span.label_large
    color: dark.$color_text-primary

  // Label Medium
  p.label_medium,
  span.label_medium
    color: dark.$color_text-inactive

  // Label Small
  p.label_small,
  span.label_small
    color: dark.$color_text-primary

  // Label Large (bold)
  p.label_large-bold,
  span.label_large-bold
    color: dark.$color_text-primary

  // Label Large (uppercase and bold)
  p.label_large-caps-bold,
  span.label_large-caps-bold
    color: dark.$color_text-inactive

  // Label Small (uppercase and bold)
  p.label_small-caps-bold,
  span.label_small-caps-bold
    color: dark.$color_text-inactive

  // Label Small (uppercase and semibold)
  p.label_small-caps-semibold,
  span.label_small-caps-semibold
    color: dark.$color_text-secondary

  // Label Extra Small (uppercase and semibold)
  p.label_extrasmall-caps-semibold,
  span.label_extrasmall-caps-semibold
    color: dark.$color_text-inactive
  
  p.inactive
    color: dark.$color_text-inactive
