@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-campaign-deliverable-v2-assign-reviewer
  background-color: $color_fill-background
  flex-shrink: 0
  height: 17.25rem
  padding: 1rem
  &-body
    align-content: center
    height: 100%
    text-align: center
    .cp_btn_component_highlight
      text-decoration: underline
  .body_large
    font-size: 1rem
    font-weight: $font-weight-bold

  .user-row
    align-items: center
    display: flex
    gap: $space_1x
    .MuiSvgIcon-root
      fill: $color_color-success
      height: px-to-rem(18px)
      width: px-to-rem(18px)
    .assign-reviewers-entity-row
      .cp_component-entity-info-row-name
        font-size: px-to-rem(10px)
        font-weight: 600
        height: px-to-rem(18px)
        margin-left: px-to-rem(10px)
      .cp_component-entity-info-row-sub-info
        color: $color_text-secondary
        font-size: px-to-rem(10px)
        font-weight: 400
        height: px-to-rem(18px)
        text-transform: capitalize
        margin-left: px-to-rem(10px)

.dark
  .cp_component_modal-campaign-deliverable-v2-assign-reviewer
    background-color: dark.$color_fill-card
    .user-row
      .MuiSvgIcon-root
        fill: dark.$color_color-success
      .assign-reviewers-entity-row
        .cp_component-entity-info-row-name
        .cp_component-entity-info-row-sub-info
          color: dark.$color_text-secondary
