@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

div.cp_campaign_page section.cp_campaign_page-tabs div.cp_component-data-grid.cp_campaign-deliverables-table_component-table
    background-color: inherit
    border: none

.cp_campaign-deliverables-table_component
    &-container
        .cp_campaign_component-tabs-content-controls.deliverables
            background-color: inherit
            justify-content: space-between
            padding: $space_1x 0 $space_3x 0
            .cp_component_search-results-search-icon
                fill: $color_icon-search
            .cp_campaign_component-tabs-content-controls-right, .cp_campaign_component-tabs-content-controls-left
                align-items: center
                display: flex
                gap: 0.75rem
                div.cp_component_input .MuiTextField-root
                    .MuiInputBase-input
                        padding: 0.25rem 0.6rem

                    .MuiSvgIcon-root.cp_component_search-results-search-icon
                        fill: $color_icon-search
                        font-size: $space_2x
                .cp_icon-button_component.filled
                    background-color: $color_button-exit
                    .MuiSvgIcon-root
                        fill: $color_icon-exit
    &-table
        div.MuiDataGrid-virtualScroller
            min-height: 400px
        div.MuiDataGrid-virtualScrollerContent
            cursor: pointer
        .table
            &-title, &-caption
                align-items: center
                display: flex
                justify-content: flex-start
                overflow: hidden
                white-space: normal
                width: 100%

                // Title text
                > p
                    font-size: 0.75rem
                    font-weight: 400
                    max-width: calc(100% - $space_5x)
                    text-transform: capitalize
                    white-space: normal
                    word-wrap: break-word

            &-caption-content
                -webkit-box-orient: vertical
                -webkit-line-clamp: 4
                display: -webkit-box
                max-width: calc(100% - $space_5x)
                overflow: hidden
                position: relative
                text-overflow: ellipsis
                white-space: normal

        &-title, &-caption, &-ttcm
            &-content
                font-size: 0.75rem
                font-weight: 400
                text-transform: capitalize
        &-ttcm
            &-content
                overflow: hidden
                text-overflow: ellipsis
        &-media
            &.MuiAvatar-root
                height: px-to-rem(60px)
                width: px-to-rem(60px)
        .MuiDataGrid-columnHeader.account-header
            padding-left: 1.25rem

.cp_campaign-deliverables-table_component-table-tooltip.MuiTooltip-tooltip
    width: 12rem

.dark
    .cp_campaign-deliverables-table_component
        &-container
            .cp_campaign_component-tabs-content-controls.deliverables
                .cp_campaign_component-tabs-content-controls-right, .cp_campaign_component-tabs-content-controls-left
                    div.cp_component_input .MuiTextField-root
                        .MuiSvgIcon-root.cp_component_search-results-search-icon
                            fill: dark.$color_icon-search
            .cp_campaign_component-tabs-content-controls.deliverables  .cp_campaign_component-tabs-content-controls-right
                .cp_icon-button_component.filled
                    background-color: dark.$color_button-exit
                    .MuiSvgIcon-root
                        fill: dark.$color_icon-exit
        &-table
            .MuiSvgIcon-root
                fill: dark.$color_icon-exit
