@use "../../style/styles" as *
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *

div.cp_component_public-list-overview-table
  .MuiDataGrid-columnHeaders
    .MuiDataGrid-columnHeader
      padding-left: $space_2x
  .MuiDataGrid-row
    background-color: $color_fill-nav
    cursor: pointer
    &.Mui-selected
      background-color: $color_fill-dropdown-select
      .cp-pill
        background-color: $color_brand-blue-teal
        border: none


.dark
  div.cp_component_public-list-overview-table
    .MuiDataGrid-row
      background-color: dark.$color_fill-nav
      &.Mui-selected
        background-color: dark.$color_fill-dropdown-select
        .cp-pill
          background-color: dark.$color_input-active
          span
            color: dark.$color_text-invert
