@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_deliverable_v2_history-item
    padding: 0.75rem 0.75rem
    &:not(:last-child)
        border-bottom: 1px solid $color_divider-section

    .main-info
        display: flex
        .deliverable-history-entity-info-row
            margin: 0
            p
                font-size: px-to-rem(10px)
        .status-tag-container
            display: flex
            gap: 0.5rem
            margin-left: auto
            .MuiSvgIcon-root
                height: px-to-rem(14px)
                width: px-to-rem(14px)
            .approve-icon
                fill: $color_color-success
            .request-revision-icon
                fill: $color_color-warning
            p 
                font-size: px-to-rem(10px)
                white-space: nowrap


    .sub-info
        margin-left: $space_5x
        .history-attributes
            display: flex
            gap: $space_1x
            .MuiSvgIcon-root
                fill: $color_icon-pill
                height: px-to-rem(16px)
                width: px-to-rem(16px)
            p 
                font-size: px-to-rem(10px)
        .history-item-content
            margin-top: $space_1x
            img
                max-width: px-to-rem(24px)
                max-height: px-to-rem(24px)
            .history-item-action
                color: $color_text-body
                font-size: px-to-rem(12px)
            .history-item-details
                align-items: center
                color: $color_text-body
                display: flex
                font-size: px-to-rem(12px)
                gap: 0.5rem
                margin-top: 0.25rem
        
.dark
    .cp_component_deliverable_v2_history-item
        &:not(:last-child)
            border-color: dark.$color_divider-section
        .main-info
            .status-tag-container
                .approve-icon
                    fill: dark.$color_color-success
                .request-revision-icon
                    fill: dark.$color_color-warning

        .sub-info
            .history-attributes
                .MuiSvgIcon-root
                    fill: dark.$color_icon-pill
            .history-item-content
                .history-item-action
                    color: dark.$color_text-body
                .history-item-details
                    color: dark.$color_text-body
