@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_campaign-deliverable-content-tab_component
    flex: 0 0 66%
    &.empty
        flex: 1
    &-container
        background-color: $color_fill-background
        border-radius: $content-border-radius
        display: flex
        gap: $space_2x
        flex-direction: column
        padding: $space_2x
    &.empty
        text-align: center
    .cp_component_empty-splash 
        .body_large
            color: $color_text-secondary
            margin-left: auto
            margin-right: auto
            max-width: 66%
    .cp-btn
        margin-top: $space_2x

.dark
    .cp_campaign-deliverable-content-tab_component
        &-container
            background-color: dark.$color_surface-primary
        .cp_component_empty-splash .body_large
            color: dark.$color_text-secondary
