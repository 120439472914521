@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp-btn.MuiButton-root
  border-radius: 2px
  font-family: "Open Sans"
  font-size: 0.75rem
  font-style: normal
  font-weight: 700
  line-height: normal
  min-width: 122px
  padding: 0.5rem 1rem

  &.MuiButtonGroup-root
  &.MuiButtonGroup-grouped
    border-color: $color_border-buttongroup

  &.cp-btn_primary
    background-color: $color_button-primary 
    color: $color_button-text 

    &:hover
      background-color: $color_button-hovered

    &:active
      background-color: $color_button-pressed

    &.cp-btn_icon
      min-width: 160px

    &.cp-btn_disabled
      background-color: $color_button-disabled
      color: $color_button-textdisabled
    
    &.cp-btn_pressed
      background-color: $color_button-pressed
      &:hover
          cursor: default

  &.cp-btn_primary_text
    color: $color_button-primary
    min-width: $space_2x
    text-transform: none

    &:hover
      background-color: $color_fill-nav
      text-decoration: underline

    &:active
      color: $color_button-primary
 
  &.cp-btn_secondary
    background-color: transparent
    border: 1px solid $color_border-secondarybutton
    color: $color_button-secondary

    &:hover
      background-color: transparent
      border: 1px solid $color_button-hovered
      color: $color_button-hovered

    &:active
      background-color: transparent
      border: 1px solid $color_button-pressed
      color: $color_button-pressed
    
    &.MuiButton-text
      color: inherit
      border: none
      min-width: fit-content
      margin-left: $space_1x
      margin-right: $space_1x

      &.cp_btn_component_highlight
        color: $color_icon-list

  &.cp-btn_size-small
    font-size: 0.666rem
    padding: 0.333rem 1rem

  &.cp-btn_size-large
    padding: 0.666rem 1rem


.dark
  .cp-btn.MuiButton-root

    &.MuiButtonGroup-root, &.MuiButtonGroup-grouped
      border-color: dark.$color_border-buttongroup

    &.cp-btn_primary
      background-color: dark.$color_button-primary 
      color: dark.$color_button-text 

      &:hover
        background-color: dark.$color_button-hovered

      &:active
        background-color: dark.$color_button-pressed

      &.cp-btn_disabled
        background-color: dark.$color_button-disabled
        color: dark.$color_button-textdisabled
      
      &.cp-btn_pressed
        background-color: dark.$color_button-pressed

    &.cp-btn_primary_text
      color: dark.$color_button-secondary
      min-width: $space_2x
      text-transform: none

      &:hover
        background-color: dark.$color_fill-nav

      &:active
        color: dark.$color_button-secondary
  
    &.cp-btn_secondary
      background-color: transparent
      border: 1px solid dark.$color_border-secondarybutton
      color: dark.$color_button-secondary

      &:hover
        background-color: transparent
        border: 1px solid dark.$color_button-hovered
        color: dark.$color_button-hovered

      &:active
        background-color: transparent
        border: 1px solid dark.$color_button-pressed
        color: dark.$color_button-pressed

      &.MuiButton-text
        color: inherit
        border: none
        min-width: fit-content
        &.cp_btn_component_highlight
          color: dark.$color_icon-list
