@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

div.cp_campaign_page section.cp_campaign_page-tabs 
div.cp_component-data-grid
  .cp_campaign-recent-deliverables-table
    background-color: inherit
    border: none

div.cp_campaign_page div.cp_component-data-grid
  padding: 0

.cp_campaign-recent-deliverables-table
  div.MuiDataGrid-virtualScroller
    min-height: 400px
  div.MuiDataGrid-virtualScrollerContent
    cursor: pointer
  .no-deliverables-overlay
  .table
    &-title, &-caption
      align-items: center
      display: flex
      justify-content: flex-start
      overflow: hidden
      white-space: normal
      width: 100%

      // Title text
      > p
        display: -webkit-box
        max-width: calc(100% - $space_5x)
        word-wrap: normal

    &-caption-content
      -webkit-box-orient: vertical
      -webkit-line-clamp: 4
      display: -webkit-box
      max-width: calc(100% - $space_5x)
      overflow: hidden
      position: relative
      text-overflow: ellipsis
      white-space: normal

    &-title, &-caption, &-ttcm
        &-content
          font-size: 0.75rem
          font-weight: 400
          text-transform: capitalize
    &-ttcm
      &-content
        overflow: hidden
        text-overflow: ellipsis
    &-media
      &.MuiAvatar-root
        height: px-to-rem(60px)
        width: px-to-rem(60px)
  .column-context
    .MuiIconButton-root
      color: $color_border-secondarybutton

.cp_campaign-recent-deliverables-table-tooltip.MuiTooltip-tooltip
  width: 12rem

.dark
  .cp_campaign-recent-deliverables-table
    .column-context
      .MuiIconButton-root
        color: dark.$color_border-secondarybutton
